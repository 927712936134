<template>
	<div class="container">
		<baseTable ref="baseTable" @request="getTableData" :queryItem="queryItem" :columns="tableCols" :otherBtns="otherBtns" showOrder />
		<el-dialog
			width="1000px"
			:title="dialogTitle"
			top="30vh"
			center
			:visible.sync="dialogVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
		>
			<formItem ref="formItem" :formItems="formItems" @submit="this.submitForm" @cancel="dialogVisible = false" :defaultData="formModel">
				<baseTable
					:columns="columns"
					:showPagination="false"
					:dataList="dataList"
					style="padding: 0 20px 20px 20px"
					:showHeader="false"
					v-if="showEditTable"
				/>
			</formItem>
		</el-dialog>
	</div>
</template>

<script>
import baseTable from '@/views/components/components-base-table';
import formItem from '@/views/components/components-form-item/index.vue';
import Vue from 'vue';
// import { setUrlParams } from '@/utils/util';
import moment from 'moment';
import { downloadByCreateA } from '@/utils/util';
// import moment from 'moment';
export default {
	name: 'reward',
	components: {
		formItem,
		baseTable
	},

	props: { pageName: {} },
	computed: {
		showEditTable() {
			const { stackType, grantType } = this.formModel;

			return stackType === '2' && grantType && grantType !== '1';
		}
	},
	data() {
		const requiredInput = { required: true, message: '请输入', trigger: 'blur' };
		const requiredSelect = { required: true, message: '请选择', trigger: 'change' };

		return {
			dialogTitle: '新增个人股权信息',
			dialogVisible: false,
			formModel: {},
			formItems: [
				{
					label: '姓名',
					key: 'staffId',
					showKey: 'staffName',
					rules: requiredSelect,
					type: 'selectSearch',
					span: 12,
					async: this.getStaffOpts
				},
				{
					label: '所在持股平台名称',
					key: 'platformId',
					rules: requiredSelect,
					type: 'select',
					async: () =>
						this.$axios
							.post('/v1/stock/platform/listByPage', { request: { pageNo: 1, pageSize: 99 } })
							.then((res) => res.list?.map(({ id, name, isOriginalPlat }) => ({ dictId: id, dictName: name, isOriginalPlat }))),
					span: 12
				},
				{ label: '授予股数', key: 'grantStockNum', rules: requiredInput, type: 'inputNumber', precision: 0, span: 12, min: 0 },
				{
					label: '授予时间',
					key: 'grantTime',
					rules: requiredSelect,
					type: 'date',
					span: 12,
					hidden: this.getHidden
				},
				{
					label: '股价',
					key: 'stockPrice',
					rules: requiredInput,
					type: 'inputNumber',
					span: 12,
					min: 0,
					hidden: this.getHidden
				},
				{
					label: '股权模式',
					key: 'stackType',
					type: 'select',
					groupId: 'stockType',
					rules: requiredSelect,
					span: 12,
					change: (data) => {
						if (data.stackType === '1') {
							Vue.delete(data, 'grantType');
							Vue.delete(data, 'effectTime');
							this.dataList = [];
						}
						if (data.stackType === '2') {
							Vue.delete(data, 'lockTime');
						}
					},
					hidden: this.getHidden
				},
				{
					label: '解锁时间',
					key: 'lockTime',
					type: 'date',
					rules: requiredSelect,
					span: 12,
					hidden: ({ stackType }) => stackType !== '1'
				},
				// { type: 'empty', span: 12 },
				{
					label: '授予形式',
					key: 'grantType',
					type: 'select',
					groupId: 'stockGrantType',
					rules: requiredSelect,
					span: 12,
					hidden: ({ stackType }) => stackType !== '2',
					change: (data) => {
						if (data.grantType !== '1') {
							Vue.delete(data, 'effectTime');
							if (data.grantType) {
								let arr = [];

								arr.length = Number(data.grantType);
								arr.fill(null);
								arr = arr.map(() => ({ sortNo: undefined, stockNum: undefined, effectTime: undefined, lockTime: undefined }));
								this.dataList = arr;
							}
						}
						if (data.grantType === '1') {
							this.dataList = [];
						}
					}
				},
				{
					type: 'empty',
					span: 12,
					hidden: ({ grantType, stackType }) => stackType !== '2' || (stackType === '2' && grantType === '1') || !grantType
				},
				{
					label: '行权时间',
					key: 'effectTime',
					type: 'date',
					rules: requiredSelect,
					span: 12,
					hidden: ({ grantType, stackType }) => grantType !== '1' || stackType !== '2'
				},
				{
					label: '解锁时间',
					key: 'lockTime',
					type: 'date',
					rules: requiredSelect,
					span: 12,
					hidden: ({ grantType, stackType }) => grantType !== '1' || stackType !== '2'
				}
			],
			tableCols: [
				{ label: '姓名', key: 'staffName' },
				{ label: '组织名称', key: 'orgName' },
				{ label: '持股平台名称', key: 'platName' },
				{ label: '职位', key: 'officeName' },
				{ label: '职级', key: 'level' },
				{ label: '股权模式', key: 'stackTypeName' },
				{ label: '授予形式', key: 'grantTypeName' },
				{ label: '行权详情信息', key: 'stockGrantDetails' },
				{
					label: '操作',
					key: 'cz',
					render: (row) => (
						<el-popover placement="left" width="96" trigger="click">
							<el-button type="text" slot="reference">
								<img src={require('../../../assets/button/操作图标@3x.png')} width="16px" />
							</el-button>
							<ul style="text-align:center">
								{this.permissionControlBtns(this.pageName, 'ChangeRatio') && (
									<li>
										<el-button type="text" onclick={() => this.$router.push({ path: 'coefficientAdjustment', query: {} })}>
											年度行权系数调整
										</el-button>
									</li>
								)}
								{this.permissionControlBtns(this.pageName, 'Edit') && (
									<li>
										<el-button type="text" onclick={() => this.handleEdit(row)}>
											编辑
										</el-button>
									</li>
								)}
								{this.permissionControlBtns(this.pageName, 'Delete') && (
									<li>
										<el-button type="text" style="color:rgb(245, 108, 108)" onclick={() => this.handleDel(row)}>
											删除
										</el-button>
									</li>
								)}
							</ul>
						</el-popover>
					)
				}
			],
			queryItem: [
				{ label: '姓名', key: 'name', type: 'input', span: 4 },
				{ label: '股权模式', key: 'stackType', type: 'select', groupId: 'stockType', span: 5 },
				{ label: '授予形式', key: 'grantType', type: 'select', groupId: 'stockGrantType', span: 5 },
				{
					label: '持股平台',
					key: 'platformId',
					type: 'select',
					async: () =>
						this.$axios
							.post('/v1/stock/platform/listByPage', { request: { pageNo: 1, pageSize: 99 } })
							.then((res) => res.list?.map(({ id, name, isOriginalPlat }) => ({ dictId: id, dictName: name, isOriginalPlat }))),
					span: 5
				}
			],
			otherBtns: [
				this.permissionControlBtns(this.pageName, 'Export') && {
					label: '导出',
					icon: 'el-icon-download',
					style: { float: 'right' },
					click: (data, f) => {
						const params = {
							...f
						};

						const outStr = encodeURIComponent(JSON.stringify(params));

						const url = `/v1/stock/export?params=${outStr}`;

						downloadByCreateA(url);
					}
				},
				this.permissionControlBtns(this.pageName, 'Add') && {
					label: '添加',
					icon: 'el-icon-plus',
					style: { float: 'right' },
					click: () => {
						this.formModel = {};
						// this.form?.resetFields();
						if (this.$refs?.formItem?.form) {
							this.$refs?.formItem.form.resetFields();
						}
						this.dialogVisible = true;
					}
				}
				// { label: '变更记录' }
			].filter((v) => v),
			dataList: [],
			columns: [
				{
					label: '一次行权',
					key: 'sortNo',
					// width: 200,
					render: (row, inx) => {
						const indexs = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十'];

						return (
							<div style="display:inline-flex;align-items:center">
								<div style="width: 100px">{indexs[inx]}次行权</div>
							</div>
						);
					}
				},
				{
					label: '最大行权股数',
					key: 'stockNum',
					width: 260,
					render: (row, inx) => (
						<div style="display:inline-flex;align-items:center">
							<div style="width: 100px">最大行权股数</div>
							<el-input-number
								size="mini"
								value={this.dataList[inx].stockNum}
								placeholder="请输入"
								precision={0}
								min={0}
								controls-position="right"
								oninput={(e) => {
									this.dataList = this.dataList.map((it, i) => {
										if (inx === i) {
											it.stockNum = e;
										}
										return it;
									});
								}}
							/>
						</div>
					)
				},
				{
					label: '行权时间',
					key: 'effectTime',
					// width: 220,
					render: (row, inx) => (
						<div style="display:inline-flex;align-items:center">
							<div style="width: 100px">行权时间</div>
							<el-date-picker
								size="mini"
								value-format="yyyy-MM-dd"
								format="yyyy-MM-dd"
								placeholder="选择时间"
								value={this.dataList[inx].effectTime}
								style="width:100%"
								oninput={(e) => {
									this.dataList = this.dataList.map((it, i) => {
										if (inx === i) {
											it.effectTime = e;
										}
										return it;
									});
								}}
							/>
						</div>
					)
				},
				{
					label: '解锁时间',
					key: 'lockTime',
					// width: 220,
					render: (row, inx) => (
						<div style="display:inline-flex;align-items:center">
							<div style="width: 100px">解锁时间</div>
							<el-date-picker
								size="mini"
								value-format="yyyy-MM-dd"
								format="yyyy-MM-dd"
								placeholder="选择时间"
								value={this.dataList[inx].lockTime}
								style="width:100%"
								oninput={(e) => {
									this.dataList = this.dataList.map((it, i) => {
										if (inx === i) {
											it.lockTime = e;
										}
										return it;
									});
								}}
							/>
						</div>
					)
				}
			]
		};
	},
	created() {},
	mounted() {},
	methods: {
		getStaffOpts(query) {
			return this.$axios
				.post('/v1/common/selectPrincipal/list', { request: { staffName: query || '' } })
				.then((v) => v.map(({ staffName, staffId }) => ({ dictId: staffId, dictName: staffName })));
		},
		getTableData(params, callback) {
			callback(this.$axios.post('/v1/stock/listStockInfoByPage', params));
		},
		// 提交
		submitForm(form) {
			if (this.dataList?.length) {
				const checkKeys = ['lockTime', 'effectTime', 'stockNum']; // sortNo
				const msgList = [];

				this.dataList.forEach((val, inx) => {
					val.sortNo = inx;
					checkKeys.forEach((key) => {
						if (!val[key] && val[key] !== 0) {
							msgList.push({ msgKey: key, rowIndex: inx });
						}
					});
				});
				if (msgList?.length) {
					return this.$message.warning('请填写完整');
				}
			}
			form.validate((valid) => {
				if (valid) {
					this.$axios
						.post('/v1/stock/saveStockInfo', { request: { ...this.formModel, stockGrantDetails: this.dataList } })
						.then((res) => {
							if (res.code) {
								return;
							}
							this.dialogVisible = false;
							this.$message.success('保存成功');
							this.$refs.baseTable?.handleSearch();
						})
						.catch(() => {
							this.dialogVisible = false;
						});
				}
			});
		},
		// 查询员工股权详情
		getDetails(id) {
			this.$axios.post('/v1/stock/findStockInfoDetailById', { request: { id } }).then((res) => {
				this.dataList =
					res?.stockGrantDetails?.map((data) => ({
						...data,
						lockTime: data.lockTime ? moment(data?.lockTime).format('YYYY-MM-DD') : '',
						effectTime: data.effectTime ? moment(data.effectTime).format('YYYY-MM-DD') : ''
					})) || [];
			});
		},

		// 编辑
		handleEdit(row) {
			this.dialogTitle = '编辑';
			this.getDetails(row.id);
			if (row.lockTime) {
				row.lockTime = moment(row.lockTime).format('YYYY-MM-DD');
			}
			if (row.grantTime) {
				row.grantTime = moment(row.grantTime).format('YYYY-MM-DD');
			}
			if (row.effectTime) {
				row.effectTime = moment(row.effectTime).format('YYYY-MM-DD');
			}
			this.formModel = { ...row };
			this.dialogVisible = true;
		},
		// 删除
		handleDel(row) {
			this.$confirm('是否确定删除?', '删除提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$axios
					.post('/v1/stock/delStockInfo', {
						request: { id: row.id },
						sign: ''
					})
					.then((res) => {
						if (res && res.code === 0) {
							this.$message.success('删除成功!');
							this.$refs.baseTable?.handleSearch();
						}
					});
			});
		},
		// 弹框 新增个人股权信息 隐藏item
		getHidden({ platformId }, opts) {
			const isOriginalPlat = opts['platformId']?.find(({ dictId }) => platformId === dictId)?.isOriginalPlat;

			return isOriginalPlat;
		}
	}
};
</script>
<style lang="scss" scoped>
.container {
	padding: 20px;
}
</style>
