<template>
	<div class="container">
		<baseTable ref="baseTable" @request="getTableData" :queryItem="queryItem" :columns="tableCols" :otherBtns="otherBtns" showOrder />
		<el-dialog
			width="600px"
			:title="dialogTitle"
			top="30vh"
			center
			:visible.sync="dialogVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
		>
			<formItem ref="formItem" :formItems="dialogItems" @submit="this.submitForm" @cancel="dialogVisible = false" :defaultData="formModel" />
		</el-dialog>
	</div>
</template>

<script>
import baseTable from '@/views/components/components-base-table';
import formItem from '@/views/components/components-form-item/index.vue';
import moment from 'moment';
export default {
	name: 'holdingPlatformPage',
	components: {
		baseTable,
		formItem
	},

	props: { pageName: {} },
	computed: {
		// typeNameOpts() {
		// 	return this.$store.state.app?.dict?.filter((item) => item.groupId === 'staffChangeType') || [];
		// }
		// 禁用删除
		getDelDisabled() {
			return ({ isOriginalPlat }) => isOriginalPlat;
		}
	},
	data() {
		return {
			// 分页参数
			pagenation: {
				pageNo: 1,
				pageSize: 20,
				totalNum: 0
			},
			// 搜索条件对象
			params: {
				pageNo: 1,
				pageSize: 20
			},
			tableData: [],
			tableCols: [
				{ label: '持股平台', key: 'name' },
				{ label: '股数', key: 'stockNum', align: 'right', formatter: (v) => v.toLocaleString() },
				{ label: '修改时间', key: 'updateTime', formatter: (val) => moment(val).format('YYYY-MM-DD') },
				{
					label: '操作',
					key: 'cz',
					render: (row, inx) => (
						<div>
							{this.permissionControlBtns(this.pageName, 'Edit') && (
								<el-button type="text" onclick={() => this.handleEdit(row)}>
									编辑
								</el-button>
							)}
							{this.permissionControlBtns(this.pageName, 'Delete') && (
								<el-button
									type="text"
									style={`color:rgb(245, 108, 108); opacity: ${this.getDelDisabled(row, inx) ? 0.6 : 1}`}
									disabled={this.getDelDisabled(row, inx)}
									onclick={() => this.handleDel(row)}
								>
									删除
								</el-button>
							)}
						</div>
					)
				}
			],
			queryItem: [
				{
					label: '持股平台',
					key: 'platformId',
					type: 'select',
					async: () =>
						this.$axios
							.post('/v1/stock/platform/listByPage', { request: { pageNo: 1, pageSize: 99 } })
							.then((res) => res.list?.map(({ id, name, isOriginalPlat }) => ({ dictId: id, dictName: name, isOriginalPlat }))),
					span: 5
				},
				{ label: '修改时间', key: 'updateTime', type: 'date', span: 5 }
			],
			dialogItems: [
				{ label: '持股平台名称', key: 'name', type: 'input', rules: { required: true, message: '请输入', trigger: 'change' } },
				{
					label: '持股数量',
					key: 'stockNum',
					type: 'inputNumber',
					precision: 0,
					rules: { required: true, message: '请输入', trigger: 'blur' }
				}
			],
			otherBtns: [
				this.permissionControlBtns(this.pageName, 'Add') && {
					label: '添加',
					icon: 'el-icon-plus',
					style: { float: 'right' },
					click: () => {
						this.dialogTitle = '添加';
						this.formModel = {};
						// this.form?.resetFields();
						if (this.$refs?.formItem?.form) {
							this.$refs?.formItem.form.resetFields();
						}
						this.dialogVisible = true;
					}
				}
			].filter((v) => v),
			dialogVisible: false,
			formModel: {},
			dialogTitle: '添加'
		};
	},
	created() {},
	mounted() {},
	methods: {
		getTableData(params, callback) {
			callback(this.$axios.post('/v1/stock/platform/listByPage', params));
		},
		// 编辑
		handleEdit(row) {
			this.dialogTitle = '编辑';
			this.formModel = { ...row };
			this.dialogVisible = true;
		},
		handleDel(row) {
			this.$confirm('是否确定删除?', '删除提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$axios
					.post('/v1/stock/platform/del', {
						request: { id: row.id },
						sign: ''
					})
					.then((res) => {
						if (res && res.code === 0) {
							this.$message.success('删除成功!');
							this.$refs.baseTable?.handleSearch();
						}
					});
			});
		},
		// 提交
		submitForm(form) {
			form.validate((valid) => {
				if (valid) {
					this.$axios
						.post('/v1/stock/platform/save', { request: this.formModel })
						.then((res) => {
							if (res.code) {
								return;
							}
							this.dialogVisible = false;
							this.$message.success('保存成功');
							this.$refs.baseTable?.handleSearch();
						})
						.catch(() => {
							this.dialogVisible = false;
						});
				}
			});
		}
	}
};
</script>
<style lang="scss" scoped>
.container {
	padding: 20px;
}
</style>
